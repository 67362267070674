
import { Options, Prop, Vue } from "vue-property-decorator";
import { IonPage, IonContent } from "@ionic/vue";
import GuestHeader from "@/components/Headers/GuestHeader.vue";

@Options({
  components: {
    GuestHeader,
    IonPage,
    IonContent,
  },
})
export default class BasicLayout extends Vue {
  public name = "GuestLayout";

  @Prop({ type: Boolean, default: () => false })
  public hideMenuButton!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public showBackButton!: boolean;

  @Prop({ type: Function, default: () => null })
  public customBackButtonFunc!: () => void;

  @Prop({ type: Boolean, default: () => false })
  public hideLogo!: any;

  @Prop({ type: Boolean, default: () => false })
  public useCustomFunc!: any;
}
