import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheoryQuestions = _resolveComponent("TheoryQuestions")!
  const _component_BasicLayout = _resolveComponent("BasicLayout")!

  return (_openBlock(), _createBlock(_component_BasicLayout, { "show-back-button": true }, {
    default: _withCtx(() => [
      _createVNode(_component_TheoryQuestions, {
        loading: _ctx.randomTheoryQuestionLoading,
        question: _ctx.randomTheoryQuestion,
        onNext: _ctx.goToNext,
        onOnAnswer: _ctx.onAnswer
      }, null, 8, ["loading", "question", "onNext", "onOnAnswer"])
    ]),
    _: 1
  }))
}